@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Passion+One&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffffff !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* model css */

.w3m-overlay {
  overflow: hidden !important;
  /* overflow-y: scroll; */
}

.w3m-container {
  display: none !important;
}
